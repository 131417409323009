"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var App_vue_1 = require("./App.vue");
var aos_1 = require("aos");
require("aos/dist/aos.css");
require("@/input.css");
var router_1 = require("./router");
var app = (0, vue_1.createApp)(App_vue_1.default);
app.use(router_1.default);
app.mount('#app');
aos_1.default.init();
