"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var aos_1 = require("aos");
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'DownArrow',
    setup: function (__props) {
        (0, vue_3.onMounted)(function () {
            aos_1.default.init();
        });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, _cache[0] || (_cache[0] = [
                (0, vue_2.createStaticVNode)("<div class=\"scroll-arrow-container\" data-v-12f169a5><div class=\"scroll-arrow\" data-v-12f169a5><svg width=\"40\" height=\"40\" viewBox=\"0 0 40 40\" data-v-12f169a5><circle class=\"circle\" cx=\"20\" cy=\"20\" r=\"19\" fill=\"transparent\" stroke=\"rgba(255,255,255,0.1)\" data-v-12f169a5></circle><path class=\"arrow\" d=\"M20 12v16M13 21l7 7 7-7\" stroke=\"white\" stroke-width=\"2\" fill=\"none\" data-v-12f169a5></path></svg></div></div>", 1)
            ])));
        };
    }
});
