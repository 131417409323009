"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "layout-container" };
var vue_router_1 = require("vue-router");
var vue_3 = require("vue");
var aos_1 = require("aos");
exports.default = (0, vue_1.defineComponent)({
    __name: 'IntroSection',
    setup: function (__props) {
        var router = (0, vue_router_1.useRouter)();
        var openResume = function () {
            window.open('/Joseph_Holm_Resume.pdf', '_blank');
        };
        var goToBlog = function () {
            router.push('/blog');
        };
        (0, vue_3.onMounted)(function () {
            aos_1.default.init();
        });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", { class: "intro-container" }, [
                    _cache[0] || (_cache[0] = (0, vue_2.createElementVNode)("div", { class: "greeting" }, "Hi, my name is", -1)),
                    _cache[1] || (_cache[1] = (0, vue_2.createElementVNode)("h1", { class: "name" }, "Joseph Holm.", -1)),
                    _cache[2] || (_cache[2] = (0, vue_2.createElementVNode)("div", { class: "title" }, "Full-Stack Developer at ASU", -1)),
                    _cache[3] || (_cache[3] = (0, vue_2.createElementVNode)("p", { class: "description" }, [
                        (0, vue_2.createTextVNode)(" I'm a sophomore at ASU pursuing a degree in Computer Science. I'm passionate about building impactful, innovative software and I'm always down for a good hackathon every now and then. "),
                        (0, vue_2.createElementVNode)("br"),
                        (0, vue_2.createElementVNode)("br"),
                        (0, vue_2.createTextVNode)(" In my spare time when I'm not coding, you'll find me reading books, watching movies or TV shows, or playing badminton and volleyball. ")
                    ], -1)),
                    (0, vue_2.createElementVNode)("button", {
                        onClick: openResume,
                        class: "resume-button"
                    }, "Check out my resume!")
                ]),
                _cache[4] || (_cache[4] = (0, vue_2.createElementVNode)("div", { class: "photo-container" }, [
                    (0, vue_2.createElementVNode)("div", { class: "placeholder-photo" }, [
                        (0, vue_2.createElementVNode)("img", {
                            src: "/assets/me2.jpg",
                            alt: "Photo"
                        })
                    ])
                ], -1))
            ]));
        };
    }
});
