"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "footer-container" };
exports.default = (0, vue_1.defineComponent)({
    __name: 'FooterSection',
    setup: function (__props) {
        var openEmail = function () {
            window.location.href = 'mailto:joseph.holm27@gmail.com';
        };
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                _cache[0] || (_cache[0] = (0, vue_2.createElementVNode)("div", { class: "footer-title" }, "Get In Touch", -1)),
                _cache[1] || (_cache[1] = (0, vue_2.createElementVNode)("p", { class: "footer-text" }, " If you have any inquiries or would like to collaborate, feel free to reach out to me on LinkedIn or send me an email using the button below. ", -1)),
                (0, vue_2.createElementVNode)("button", {
                    class: "contact-button",
                    onClick: openEmail
                }, " Contact "),
                _cache[2] || (_cache[2] = (0, vue_2.createStaticVNode)("<div class=\"footer-bottom\" data-v-0b966c36><p class=\"copyright\" data-v-0b966c36>Built by Joseph Holm</p><div class=\"source-code\" data-v-0b966c36><img src=\"/assets/github.svg\" alt=\"GitHub\" class=\"github-icon\" data-v-0b966c36><a href=\"https://github.com/Hololm/portfolio\" target=\"_blank\" data-v-0b966c36> Source Code - GitHub </a></div></div>", 1))
            ]));
        };
    }
});
